const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
    title_xls: 'No',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'kode_distri_si',
    title_xls: 'Kode Produk',
    // width: 150,
  },
  {
    title: 'Nama Distrik',
    dataIndex: 'nama_distrik',
    title_xls: 'Nama Distrik',
    //  width: 200,
  },
  {
    title: 'Kode Gudang',
    dataIndex: 'kode_gudang_si',
    title_xls: 'kode gudang si',
    //  width: 150,
  },
  {
    title: 'Gudang',
    dataIndex: 'gudang',
    title_xls: 'Gudang',
    // width: 180,
  },
  {
    title: 'Alamat',
    dataIndex: 'alamat',
    title_xls: 'Alamat',
    //  width: 320,
  },
  {
    title: 'Kemampuan Bongkar Gudang',
    dataIndex: 'kapasitas_bongkar',
    slots: { customRender: 'nilai' },
    // width: 150,
  },
  {
    title: 'SO Hari ini + CO',
    dataIndex: 'so_hari_ini',
    slots: { customRender: 'nilai' },
    // width: 150,
  },
  {
    title: 'Ketahanan SO (Hari)',
    dataIndex: 'ketahanan_so',
    slots: { customRender: 'ketahanan' },
    // width: 150,
  },
  {
    title: 'Kapasitas (ZAK)',
    dataIndex: 'kapasitas_gudang',
    // width: 150,
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Volume Stok Gudang (ZAK)',
    dataIndex: 'volume_stock_gudang',
    slots: { customRender: 'nilai' },
    // width: 100,
  },
  {
    title: 'Level Stok Gudang (All Brand)',
    dataIndex: 'level_stock',
    // width: 150,
    slots: { customRender: 'levelStok' },
  },
  {
    title: 'Rata-rata Sell Out (ZAK)',
    dataIndex: 'penjualan_avg',
    slots: { customRender: 'pembulatan' },
    // width: 150,
  },
  {
    title: 'Sell Out (ZAK)',
    dataIndex: 'sell_out',
    slots: { customRender: 'nilai' },
    // width: 150,
    title_xls: 'Sell Out[Penjualan Distributor](ZAK)',
  },
  {
    title: 'Ketahanan Stok (Hari)',
    dataIndex: 'ketahanan_stock',
    // width: 150,
    slots: { customRender: 'ketahanan' },
  },
  {
    title: 'Antrian Truk',
    dataIndex: 'antrian_truk',
    slots: { customRender: 'nilai' },
    // width: 150,
  },
  {
    title: 'Status Gudang',
    dataIndex: 'status_gudang',
    // width: 150,
    title_xls: 'Status Gudang Terhadap Sisa SO Total',
    slots: { customRender: 'statusGudang' },
  },
  {
    title: 'Action Plan',
    dataIndex: 'action_plant',
    // width: 300,
    slots: { customRender: 'actionPlan' },
  },
  {
    title: 'Kekurangan SO',
    dataIndex: 'kekurangan_so',
    slots: { customRender: 'nilai' },
    // width: 150,
  },
  {
    title: 'Siap Kirim Hari ini',
    dataIndex: 'siap_kirim',
    slots: { customRender: 'nilai' },
    // width: 150,
  },
  {
    title: 'SO Clean & Clear (TON)',
    dataIndex: 'ssc',
    slots: { customRender: 'nilai' },
    // width: 150,
  },
  {
    title: 'PIC',
    dataIndex: 'pic',
    // width: 100,
  },
  {
    title: 'Ship Out (ZAK)',
    dataIndex: 'ship_out',
    slots: { customRender: 'nilai' },
    // width: 150,
    title_xls: 'Ship Out[Pengiriman Pabrik h-1](ZAK)',
  },
  {
    title: 'Sell In (ZAK)',
    dataIndex: 'sell_in',
    slots: { customRender: 'nilai' },
    // width: 150,
    title_xls: 'Sell In [Penerimaan Gdg Distributor](ZAK)',
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Distributor',
    dataIndex: 'distibutor',
    // width: 250,
  },
  {
    title: 'Distrik',
    dataIndex: 'distrik',
    // width: 150,
  },
  {
    title: 'Kode Distributor SBI',
    dataIndex: 'kd_distri_sbi',
    slots: { customRender: 'nilai' },
    // width: 150,
  },
  {
    title: 'Tanggal Stok',
    dataIndex: 'tanggal_stock',
    // width: 150,
  },
]
export default acolumns
