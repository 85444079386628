<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-province
          class="mr-2 mt-2"
          v-model:value="provinsi"
          :label-in-value="true"
          @change="onProvinsiChange"
          v-can:hide.distributor
        />
        <filter-distributor
          class="mr-2 mt-2"
          v-model:value="distributor"
          v-model:provinsi="provinsiValue"
          v-can:hide.distributor
        ></filter-distributor>
        <filter-brands class="mt-2" v-model:value="brand" v-model:brand="brand" />
      </div>
      <div class="col-md-12">
        <a-date-picker
          class="mr-2 mt-2"
          v-model:value="end_date"
          placeholder="Tanggal"
          style="width: 300px"
          format="DD MMMM YYYY"
        />
        <a-select
          :options="[
            { value: 1, label: 'Gudang Fisik' },
            { value: 2, label: 'Gudang Virtual' },
            { value: 3, label: 'Gudang Proyek' },
            { value: 4, label: 'Gudang Direct Address' },
          ]"
          :mode="null"
          allow-clear
          class="mr-2 mt-2"
          style="width: 300px;"
          placeholder="Pilih Jenis Gudang"
          v-model:value="type"
        >
        </a-select>
        <a-button
          class=" ml-2 mr-2 mt-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button title="Download Excel" type="primary" @click="fetchXlsx" :loading="isDownloading">
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <div class="table-responsive">
              <md-table
                :columns="columns"
                :data-source="data"
                row-key="id"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <!-- <template #footer>
                  <tr className='ant-table-row  ant-table-row-level-0'>
                    <td>TOTAL</td>
                    <td>{{ summary.kapasitas_bongkar }}</td>
                    <td>{{ summary.so_hari_ini }}</td>
                    <td>{{ summary.so_ketahanan }}</td>
                    <td>{{ summary.kapasitas_gudang }}</td>
                    <td>{{ summary.volume_stock_gudang }}</td>
                    <td>{{ summary.level_stock }}</td>
                    <td>{{ summary.penjualan_avg }}</td>
                    <td>{{ summary.sell_out }}</td>
                    <td>{{ summary.ketahanan_stock }}</td>
                    <td>{{ summary.antrian_truk }}</td>
                    <td>{{ summary.kekurangan_so }}</td>
                    <td>{{ summary.siap_kirim }}</td>
                    <td>{{ summary.ssc }}</td>
                    <td>{{ summary.ship_out }}</td>
                    <td>{{ summary.sell_in }}</td>
                  </tr>
                </template> -->
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #customer="{ text }">
                  <span>
                    {{ text }}
                  </span>
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>
                <template #missing="{ text }">
                  <span
                    :class="[
                      text === 'Aktif'
                        ? 'font-size-12 badge badge-primary'
                        : 'font-size-12 badge badge-default',
                    ]"
                    >missing</span
                  >
                </template>
                <template #nilai="{ text }">
                  <span>{{ text ? text : 0 }}</span>
                </template>
                <template #pembulatan="{ text }">
                  <span>{{ text ? Math.round(text) : 0 }}</span>
                </template>
                <template #ketahanan="{ text }">
                  <span :class="[parseInt(text) < 7 || !text ? 'text-custom-red' : null]">{{
                    text ? Math.round(text) : 0
                  }}</span>
                </template>
                <template #levelStok="{ text }">
                  <span v-if="parseInt(text) >= 100" class="text-custom-red">{{
                    text ? Math.round(text) + '%' : 0
                  }}</span>
                  <span
                    v-else-if="parseInt(text) < 100 && parseInt(text) >= 80"
                    class="text-custom-orange"
                    >{{ text ? Math.round(text) + '%' : 0 }}</span
                  >
                  <span v-else class="text-custom-dark">{{
                    text ? Math.round(text) + '%' : 0
                  }}</span>
                </template>
                <template #statusGudang="{ text }">
                  <span v-if="text === 'SIAP KIRIM'" class="text-custom-green">{{ text }}</span>
                  <span v-else-if="text === 'SO KURANG'" class="text-custom-orange">{{
                    text
                  }}</span>
                  <span v-else-if="text === 'GUDANG PENUH'" class="text-custom-red">{{
                    text
                  }}</span>
                  <span v-else class="text-custom-yellow">{{ text }}</span>
                </template>
                <template #actionPlan="{ text }">
                  <span v-if="text === 'OPTIMALISASI KIRIMAN'" class="text-custom-green">{{
                    text
                  }}</span>
                  <span v-else-if="text === 'PENAMBAHAN SO'" class="text-custom-orange">{{
                    text
                  }}</span>
                  <span
                    v-else-if="text === 'OPTIMALISASI SALE OUT DAN BONGKARAN'"
                    class="text-custom-red"
                    >{{ text }}</span
                  >
                  <span v-else class="text-custom-yellow">{{ text }}</span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  nextTick,
  toRef,
  onMounted,
  reactive,
  watch,
  provide,
  toRefs,
  toRaw,
  computed,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterDistributor from '@/components/filter/FilterDistributorOI'
import FilterBrands from '@/components/filter/FilterBrand'
import { useDisabledDate } from '@/composables/DisabledDate'
import acolumns from './columns'
import qs from 'qs'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'

export default defineComponent({
  components: {
    FilterProvince,
    FilterDistributor,
    FilterBrands,
  },
  setup() {
    const columns = ref([])
    const data = ref([])
    const summary = ref([])

    columns.value = acolumns

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')
    const checked = ref(false)

    const type = ref(null)
    const provinsi = ref([])
    const provinsiValue = ref([])
    const provinsiLabel = ref([])
    const distributor = ref([])
    const { isDistributor, vendor_id } = useUserInfo()
    if (isDistributor.value) {
      distributor.value.push(vendor_id.value)
    }
    const brand = ref([])
    const virtual = ref(2)

    const errorMessage = ref(null)

    const {
      start_date,
      end_date,
      startDate,
      endDate,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()
    end_date.value = moment(new Date()).subtract(1, 'days')

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        provinsi: provinsiValue.value,
        brand: brand.value,
        distributor: distributor.value,
        start_date: endDate.value,
        end_date: endDate.value,
        virtual: virtual.value,
        type: type.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/report-oi/level-stock', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
      apiClient
        .get('api/report-oi/level-stock', {
          params: { ..._params, _total: true },
        })
        .then(response => {
          summary.value = response.data
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report-oi/level-stock', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          const listprovinsi = provinsiLabel.value.length ? provinsiLabel.value.join('-') : 'ALL'
          const tanggal = endDate.value
          const filename = 'MDXL-Potensi_Penjualan_detail-' + listprovinsi + tanggal + '.xlsx'
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const onCheckVirtual = e => {
      checked.value = e.target.checked
      if (e.target.checked) {
        virtual.value = 1
      } else {
        virtual.value = 2
      }
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const onProvinsiChange = () => {
      provinsiValue.value = []
      provinsiLabel.value = []
      provinsi.value.forEach(item => {
        provinsiValue.value.push(item.value)
        provinsiLabel.value.push(item.label)
      })
    }

    return {
      q,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      handleTableChange,
      startRow,
      provinsi,
      provinsiValue,
      provinsiLabel,
      onProvinsiChange,
      brand,
      onSelectChange,
      state,
      fetchXlsx,
      distributor,
      fetchData,
      ...toRefs(state),
      start_date,
      end_date,
      firstDayMonth,
      lastDayMonth,
      disabledStartDate,
      disabledEndDate,
      search,
      errorMessage,
      checked,
      virtual,
      onCheckVirtual,
      type,
      summary,
    }
  },
})
</script>

<style>
.ant-table-tbody > tr.ant-table-row > td.row-cell-red {
  background: #ff0000 !important;
  color: white;
}

.ant-table-tbody > tr.ant-table-row > td.row-cell-orange {
  background: #ffa500 !important;
  color: white;
}

.ant-table-tbody > tr.ant-table-row > td.row-cell-yellow {
  background: #eedf11 !important;
  color: black;
}

.ant-table-tbody > tr.ant-table-row > td.row-cell-dark {
  background: #000000 !important;
  color: white;
}

.ant-table-tbody > tr.ant-table-row > td.row-cell-green {
  background: #008000 !important;
  color: white;
}

.text-custom-red {
  color: #ff0000;
}

.text-custom-orange {
  color: #ffa500;
}

.text-custom-yellow {
  color: #eedf11;
}

.text-custom-dark {
  color: #000000;
}

.text-custom-green {
  color: #008000;
}
</style>
